import './style';
import { NFTE } from '@nfte/react';


const App = () => {
    return (
        <div id="outerContainer">
            <div id="container1">
                <h1>Substitute</h1>
                <h2>A Photomicroscopic Opera</h2>
                <div id="collectionDescription" className="text">
                Substitute is a series of eight NFTs that recount an epic narrative through the medium of photomicroscopy.
                <br />
                <br />
                The photographic subject of this series is stevia, a natural sugar substitute. Stevia is harvested from the leaves of the stevia rebaudiana herb, a relative of the sunflower that is native to Brazil and Peru.              
                <br />
                <br />
                Under the microscope, the sweet steviol crystals reveal a realm of dramatic beauty and sadness. Through meticulous preparation and experimentation, the artists transform these otherwise dull crystals into the actors and scene of a mythic story. Haunting landscapes and ecstatic bursts of color emerge from the light bent by their intricate molecular structures. 
                <br />
                <br />
                Artists <a href="https://twitter.com/nnnnicholas">Nicholas</a> and <a href="https://twitter.com/raphaelddddd">Raphael Demers</a> captured these unique images with an optical microscope prepared with polarizing light filters. Substitute was created at the <a href="https://fablabdupec.com">Fab Lab du PEC</a>, an arts-oriented digital fabrication laboratory in Montreal, Canada in 2018. Substitute was minted in February, March and April of 2021, making it one of the earliest photography collections created on Foundation, and perhaps the first photomicroscopy NFT ever.
            </div>
                {/* <div id="credits" class="text"></div>              </div> */}
                {/* <div id="status" class="text">
                  <div>Sold</div>
                  <div>3 of 8</div></div> */}
            </div>
            <div id="container2">
                {/* Methylation */}
                <NFTE contract="0x3B3ee1931Dc30C1957379FAc9aba94D1C48a5405" darkMode tokenId="138" />
                {/* Arrival */}
                <NFTE contract="0x3B3ee1931Dc30C1957379FAc9aba94D1C48a5405" darkMode tokenId="265" />
                {/* Threaten */}
                <NFTE contract="0x3B3ee1931Dc30C1957379FAc9aba94D1C48a5405" darkMode tokenId="287" />
                {/* Collimator */}
                <NFTE contract="0x3B3ee1931Dc30C1957379FAc9aba94D1C48a5405" darkMode tokenId="8932" />
                {/* Breakfast */}
                <NFTE contract="0x3B3ee1931Dc30C1957379FAc9aba94D1C48a5405" darkMode tokenId="8942" />
                {/* Lillith */}
                <NFTE contract="0x3B3ee1931Dc30C1957379FAc9aba94D1C48a5405" darkMode tokenId="8943" />
                {/* Archaeon */}
                <NFTE contract="0x3B3ee1931Dc30C1957379FAc9aba94D1C48a5405" darkMode tokenId="14756" />
                {/* Portal */}
                <NFTE contract="0x3B3ee1931Dc30C1957379FAc9aba94D1C48a5405" darkMode tokenId="21497" />
            </div>
            <footer>
                © 2021
            <a href="https://twitter.com/nnnnicholas">@nnnnicholas</a> (<a href="https://foundation.app/nicholas">Foundation</a>) and <a href="https://twitter.com/raphaelddddd">Raphaël Demers</a> (<a href="https://foundation.app/raphaeld">Foundation</a>)
          </footer>
        </div>
    )
}

export default App;
